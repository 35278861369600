import {BREAK_POINTS} from './setting';

function slick() {
	$('.slick-main').slick({
		autoplay: true,
		autoplaySpeed: 4000,
		speed: 2000,
		fade: true,
		arrows: false,
		pauseOnHover: false,
		pauseOnDotsHover: false,
		pauseOnFocus: false,
	});
}

$(function(){
	slick();
});
